import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { useStore } from '@nanostores/react';
import {
  addToCart,
  computedCart,
  ledger as $ledger,
  removeFromCart,
} from '@/libs/stores/cart';
import { formatCentsToDollars } from '@/libs/utils';
const products = [
  {
    id: 1,
    name: 'Throwback Hip Bag',
    href: '#',
    color: 'Salmon',
    price: '$90.00',
    quantity: 1,
    imageSrc:
      'https://tailwindui.com/img/ecommerce-images/shopping-cart-page-04-product-01.jpg',
    imageAlt:
      'Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt.',
  },
  {
    id: 2,
    name: 'Medium Stuff Satchel',
    href: '#',
    color: 'Blue',
    price: '$32.00',
    quantity: 1,
    imageSrc:
      'https://tailwindui.com/img/ecommerce-images/shopping-cart-page-04-product-02.jpg',
    imageAlt:
      'Front of satchel with blue canvas body, black straps and handle, drawstring top, and front zipper pouch.',
  },
  // More products...
];

export default function CartIconButton() {
  const cart = useStore(computedCart);
  const ledger = useStore($ledger);

  return (
    <Sheet>
      <SheetTrigger>
        <div class="text-black dark:text-white hover:text-gray-500 inline-flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-shopping-cart"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M17 17h-11v-14h-2"></path>
            <path d="M6 5l14 1l-1 7h-13"></path>
          </svg>
          <span
            id="cart-count"
            class="ml-2 text-sm font-medium text-gray-700 dark:text-gray-300 group-hover:text-gray-800"
          >
            {cart.length}
          </span>
          <span class="sr-only">items in cart, view bag</span>
        </div>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Shopping cart</SheetTitle>
        </SheetHeader>
        <div className="flex h-full flex-col">
          <div className="flex-1 overflow-y-auto py-6 ">
            <div className="flex items-start justify-between"></div>

            <div className="mt-8">
              <div className="flow-root">
                <ul role="list" className="-my-6 divide-y divide-gray-200">
                  {cart.map((cartItem) => (
                    <li key={cartItem.cartItem.name} className="flex py-6">
                      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                        <img
                          src={cartItem.cartItem.image}
                          alt={cartItem.cartItem.name}
                          className="h-full w-full object-cover object-center"
                        />
                      </div>

                      <div className="ml-4 flex flex-1 flex-col">
                        <div>
                          <div className="flex justify-between text-base font-medium text-gray-900">
                            <h3>
                              <a
                                href={`/${
                                  cartItem.cartItem.type
                                }s/${encodeURIComponent(
                                  cartItem.cartItem.name
                                )}`}
                              >
                                {cartItem.cartItem.name}
                              </a>
                            </h3>
                            <p className="ml-4">
                              ${formatCentsToDollars(cartItem.cartItem.price)}
                            </p>
                          </div>
                          {/* <p className="mt-1 text-sm w-28 text-gray-500 truncate text-ellipsis">
                            {cartItem.cartItem.description}
                          </p> */}
                        </div>
                        <div className="flex flex-1 items-end justify-between text-sm">
                          <p className="text-gray-500">
                            Qty {cartItem.quantity}
                          </p>
                          {cartItem.type !== 'shipping' && (
                            <div className="flex space-x-2">
                              <button
                                onClick={() => {
                                  addToCart(cartItem.cartItem, cartItem.type);
                                }}
                                type="button"
                                className="font-medium text-slate-600 hover:text-slate-500"
                              >
                                Add
                              </button>
                              <button
                                onClick={() => {
                                  removeFromCart(cartItem);
                                }}
                                type="button"
                                className="font-medium text-slate-600 hover:text-slate-500"
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
            <div className="flex justify-between text-base font-medium text-gray-900">
              <p>Subtotal</p>
              <p>${ledger.total}</p>
            </div>
            <p className="mt-0.5 text-sm text-gray-500">
              Shipping and taxes calculated at checkout.
            </p>
            <div className="mt-6">
              <a
                href="/store/checkout"
                className="flex items-center justify-center rounded-md border border-transparent bg-slate-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-slate-700"
              >
                Checkout
              </a>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}
